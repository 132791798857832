import React from 'react';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
  } from '@mui/material';

  import {
    barcodingKitLabelToNameByPlatform
} from './canonicalizeMetadata';

function BarcodingKitSelect(props) {
    const {
        barcodingKit,
        setBarcodingKit,
        platform
    } = props;

    const options = {
        ...barcodingKitLabelToNameByPlatform[platform],
    }

    return (
        <FormControl variant="standard" fullWidth>
            <FormControl variant="standard" fullWidth>
                <InputLabel id="barcoding-kit-label">{platform === 'illumina' ? 'Index' : 'Barcoding'} Kit</InputLabel>
                <Select
                    fullWidth
                    labelId="barcoding-kit-label"
                    id="barcoding-kit-select"
                    value={barcodingKit}
                    label="Barcoding Kit"
                    onChange={(event) => setBarcodingKit(event.target.value)}
                >
                    {Object.keys(options).map((key) => {
                        return <MenuItem value={key}>{options[key]}</MenuItem>
                    })}
                </Select>
                <FormHelperText>Optional</FormHelperText>
            </FormControl>
        </FormControl>
    );
}

export default BarcodingKitSelect;