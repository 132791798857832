import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import RunStatusList from './Runs/RunStatusList';
import AddSamplesDialog from './Samples/AddSamplesDialog';
import AddRunDialog from './Runs/AddRunDialog';

import { datadogRum } from '@datadog/browser-rum';
import DatasetsDataGrid from './Datasets/DatasetsDataGrid.jsx';

function Project() {
  const {
    activeProject,
    activeDataset,
    addSamplesDialogOpen,
    datasetsGridRef,
    datasetsGridRows,
    datasetStatusRef,
    datasets,
    samples,
    setAddSamplesDialogOpen,
    setActiveDataset,
    setLoadStatuses,
    setReloadDatasets,
    setSamples,
    uploadFile,
    uploadingFilesCt,
    userData,
    recentlyViewedDatasets,
    visibleRuns,
    setVisibleRuns,
    setUserErrorMessage
  } = useOutletContext();
  const { getAccessTokenSilently } = useAuth0();

  const [addDatasetDialogOpen, setAddDatasetDialogOpen] = useState(false);
  const [reloadSamples, setReloadSamples] = useState(false);

  /**
   * On change to activeDataset state, if an active dataset is set, load
   * samples associated with that run and add them to samples
   * state.
   */
  useEffect(() => {
    if (!activeDataset) {
      setSamples(null);
      return;
    }

    if (reloadSamples) {
      setReloadSamples(false);
    }

    getAccessTokenSilently().then(accessToken => {
      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });

      return fetch(
        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${activeDataset.id}/lib_seqs`,
        {
          method: 'GET',
          headers,
        },
      )
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
        }

        return res.json()
      })
      .then((response) => {
        setSamples(response.data.sort((a, b) => a.id - b.id));
      })
      .catch(datadogRum.addError)
  }, [getAccessTokenSilently, activeDataset, setSamples, reloadSamples]);

  const recentlyViewedDatasetsContent = recentlyViewedDatasets ? 
    (
      <RunStatusList
        runs={recentlyViewedDatasets}
        activeRun={activeDataset}
        setActiveRun={setActiveDataset}
        setSamples={setSamples}
      />
    ) : null;

  const createNewDatasetButton = activeDataset ?
    null : (
      <Button
        size="large"
        sx={{ marginRight: '10px', marginTop: '30px' }}
        variant="contained"
        color="info"
        aria-label="create-dataset"
        onClick={() => setAddDatasetDialogOpen(true)}
        startIcon={<AddIcon />}>
        Create New Dataset
      </Button>
    )
      

  return (
    <Grid container maxWidth="lg" spacing={2} sx={{ m: 'auto', mt: 1, paddingRight: '30px' }}>
      <Grid item xs={12} sx={{marginTop: '50px'}}>
        <p style={{color: '#797979', marginBottom: '2px', marginTop: '2px'}}>
          <b>RECENTLY VIEWED DATASETS</b>
        </p>
      </Grid>
      {recentlyViewedDatasetsContent}
      <Grid item xs={12} sm={8} sx={{ marginTop: '50px'}}>
        <p style={{color: '#797979', marginBottom: '2px', marginTop: '2px'}}>
          <b>SEQUENCING DATASETS</b>
        </p>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ textAlign: 'right'}}>
          {createNewDatasetButton}
        </Box>
      </Grid>
      <Grid 
        item 
        xs={12} 
        height="665px" 
        sx={{
          '& .keynome-table--header': { backgroundColor: "#eee" },
          marginBottom: '40px'
        }}
      >
        <DatasetsDataGrid
          datasets={datasets}
          datasetsGridRef={datasetsGridRef}
          datasetsGridRows={datasetsGridRows}
          datasetStatusRef={datasetStatusRef}
          setAddSamplesDialogOpen={setAddSamplesDialogOpen}
          setActiveRun={setActiveDataset}
          setLoadStatuses={setLoadStatuses}
        />
      </Grid>
      <AddSamplesDialog 
        open={addSamplesDialogOpen || activeDataset} 
        setOpen={setAddSamplesDialogOpen} 
        activeRun={activeDataset} 
        reloadSamples={() => setReloadSamples(true)} 
        uploadFile={uploadFile} 
        backgroundUploadingFilesCt={uploadingFilesCt}  
        samples={samples} 
        activeProject={activeProject} 
        setSamples={setSamples} 
        setActiveRun={setActiveDataset} 
        setReloadRuns={setReloadDatasets}
        visibleRuns={visibleRuns}
        setVisibleRuns={setVisibleRuns}
        setUserErrorMessage={setUserErrorMessage}
      />
      <AddRunDialog 
        open={addDatasetDialogOpen} 
        setOpen={setAddDatasetDialogOpen} 
        activeProject={activeProject} 
        userData={userData} 
        reloadRuns={() => setReloadDatasets(true)} 
        setUserErrorMessage={setUserErrorMessage}
      />
    </Grid>
  );
}

export default Project;
