import React from 'react';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
  } from '@mui/material';

  import {
    libraryPrepKitLabelToNameByPlatform
} from './canonicalizeMetadata';

function PrepKitSelect(props) {
    const {
        libraryPrepKit,
        setLibraryPrepKit,
        platform
    } = props;

    const options = {
        ...libraryPrepKitLabelToNameByPlatform[platform],
    }

    return (
        <FormControl variant="standard" fullWidth>
        <InputLabel id="prep-kit-label">Library Prep Kit</InputLabel>
        <Select
            fullWidth
            labelId="prep-kit-label"
            id="prep-kit-select"
            value={libraryPrepKit}
            label="Library Prep Kit"
            onChange={(event) => setLibraryPrepKit(event.target.value)}
        >
            {Object.keys(options).map((key) => {
                return <MenuItem value={key}>{options[key]}</MenuItem>
            })}
        </Select>
        <FormHelperText>Optional</FormHelperText>
        </FormControl>
    );
}

export default PrepKitSelect;