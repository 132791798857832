import React from 'react';
import { Box, Grid } from '@mui/material';

function DetailedSequencingQualitySummary(props) {
  const {
    analysis
  } = props;

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>SEQUENCING DATA QUALITY</b></p>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box sx={{ padding: '15px 40px' }}>
            <Grid container>
        <Grid item xs={12} sm={6}>
            <p>
              <b>Q20 Rate:</b>
              {' '}
              {(analysis && analysis.attributes.q20_rate )? (analysis.attributes.q20_rate * 100).toFixed(2) : '-'}%
            </p>
            <p>
              <b>Adapter Content:</b>
              {' '}
              {(analysis && analysis.attributes.adapter_rate) ? (analysis.attributes.adapter_rate * 100).toFixed(2) : '-'}%
            </p>
            <p>
              <b>Duplication Rate:</b>
              {' '}
              {(analysis && analysis.attributes.duplication_rate) ? (analysis.attributes.duplication_rate * 100).toFixed(2) : '-'}%
            </p>
      </Grid>
        <Grid item xs={12} sm={6}>
            <p>
              <b>Q30 Rate:</b>
              {' '}
              {(analysis && analysis.attributes.q30_rate) ? (analysis.attributes.q30_rate * 100).toFixed(2) : '-'}%
            </p>
            <p>
              <b>GC Content:</b>
              {' '}
              {(analysis && analysis.attributes.gc_content) ? (analysis.attributes.gc_content * 100).toFixed(2) : '-'}%
            </p>
            </Grid>
        <Grid item xs={12}>
            <p>
              <b>Average Read Length:</b>
              {' '}
              {(analysis && analysis.attributes.human_reads_removed_megabases) ? Math.round(analysis.attributes.human_reads_removed_megabases * 1000000 / analysis.attributes.human_reads_removed_reads).toLocaleString() : '-'} bases
            </p>
            <p>
              <b>Max Read Length:</b>
              {' '}
              {(analysis && analysis.attributes.human_reads_removed_max_read_length) ? analysis.attributes.human_reads_removed_max_read_length.toLocaleString() : '-'} bases
                <br />
            <small>&nbsp;</small>
            </p>
        </Grid>
        </Grid>
      </Box>
      </Grid>
      </Grid>
    </>
  );
}

export default DetailedSequencingQualitySummary;
