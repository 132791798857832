import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { slugify } from '../utils';

function ProjectSelect(props) {
  const { projects, reloadProjects, activeProject, onActiveProjectChange, setActiveProject } = props;
  const [friendlyName, setFriendlyName] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setFriendlyName('');
    setNewProjectName('');
    setError('');
  };

  const handleActiveProjectChange = (event) => {
    // Only handle onChange for regular project selections
    if (event.target.value !== 'add-new') {
      onActiveProjectChange(event);
    }
  };

  const handleAddProject = (event) => {
    setError('');
    const newFriendlyName = event.target.value;
    const slugifiedName = slugify(newFriendlyName);
    setFriendlyName(newFriendlyName);
    setNewProjectName(slugifiedName);
  };

  const createNewProject = async (headers, organizationID, projectName) => {
    const res = await fetch(
      `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organizationID}/projects`,
      {
        method: 'POST',
        headers: { ...headers, 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            type: "project",
            attributes: { name: projectName }
          }
        })
      }
    );

    if (!res.ok) {
      if (res.status === 400) {
        setError('Project name already exists - please choose a different name');
        return;
      }
      if (res.status === 422) {
        setError('Project name must be at least 5 characters and no more than 50 characters');
        return;
      }
      else {
        throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`) 
      };
    }
    return res.json();
  };

  const handleSubmit = async () => {
    if (!newProjectName.trim()) return;
    setIsSubmitting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };      
      const newProject = await createNewProject(headers, activeProject.attributes.organization_id, newProjectName);
      if (!newProject) {
        return;
      }
      const updatedProjects = await reloadProjects();
      const createdProject = updatedProjects.find(p => p.id === newProject?.data?.id);
      if (!createdProject) {
        throw new Error('Created project not found in updated list');
      }
      setActiveProject(createdProject);
      handleDialogClose();
      
    } catch (error) {
      datadogRum.addError(error);
      alert(`Failed to create new project: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!activeProject) {
    return null;
  }

  return (
    <>
    <FormControl fullWidth>
      <InputLabel id="select-project-label">Project</InputLabel>
      <Select
        labelId="select-project-label"
        id="select-project"
        value={activeProject.id}
        label="Project"
        onChange={handleActiveProjectChange}
      >
        {
          projects
          .filter(project => project.attributes.name !== "archive")
          .map(
            (project, idx) => (
              <MenuItem
                value={project.id}
                idx={idx}
                key={idx}
              >
                {project.attributes.name}
              </MenuItem>
            ),
          )
        }
        <MenuItem
          value="add-new"
          onClick={handleDialogOpen}
          sx={{ color: 'success.main' }
        }>
          <AddIcon sx={{ mr: 1, color: 'success.main' }} />
          Add New
        </MenuItem>
      </Select>
    </FormControl>
    <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
    <DialogTitle>Create New Project</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Project Name"
        fullWidth
        value={friendlyName}
        variant="standard"
        onChange={handleAddProject}
        error={!!error}
        helperText={friendlyName ? `Label: ${newProjectName}${error ? ` - ${error}` : ''}` : ''}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDialogClose}>Cancel</Button>
      <Button 
        onClick={handleSubmit}
        disabled={!!error || !newProjectName.trim() || isSubmitting}
        variant="contained"
        color="primary"
      >
        Create
      </Button>
    </DialogActions>
  </Dialog>
</>
  );
}

export default ProjectSelect;
