import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useOutletContext } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';

import LinearProgress from '@mui/material/LinearProgress';
import IdentifiedSpeciesRow from './IdentifiedSpecies/IdentifiedSpeciesRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DetailedRunMetadataSummary from './Runs/DetailedRunMetadataSummary';
import DetailedSampleMetadataSummary from './Samples/DetailedSampleMetadataSummary';
import DetailedSequencingYieldSummary from './Samples/DetailedSequencingYieldSummary';
import DetailedSequencingQualitySummary from './Samples/DetailedSequencingQualitySummary';
import DetailedAssemblyQualitySummary from './Samples/DetailedAssemblyQualitySummary';
import TestMetadataSummary from './Tests/TestMetadataSummary';
import DiagnosticSampleMetadataSummary from './Samples/DiagnosticSampleMetadataSummary';
import UHETestSummary from './Tests/UHETestSummary';
import LPSeqTestSummary from './Tests/LPSeqTestSummary';
import TestPanel from './Tests/TestPanel';
import BackButton from './Buttons/BackButton';
import IdentifiedSpeciesFiltersDialog from './Samples/IdentifiedSpeciesFiltersDialog';

function Sample() {
  const params = useParams();

  const { activeProject } = useOutletContext();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [run, setRun] = useState(null);
  const [sample, setSample] = useState(null);
  const [activeAnalysis, setActiveAnalysis] = useState(null);
  const [activeAnalysisReports, setActiveAnalysisReports] = useState(null);
  const [identifiedSpecies, setIdentifiedSpecies] = useState(null);
  const [allIdentifiedSpecies, setAllIdentifiedSpecies] = useState(null);
  const [canShowDiagnosticView, setCanShowDiagnosticView] = useState(false);
  const [isDiagnosticView, setIsDiagnosticView] = useState(false);
  const [showAST, setShowAST] = useState(true);
  const [showKIDCalls, setShowKIDCalls] = useState(true);
  const [visibleSpeciesCt, setVisibleSpeciesCt] = useState(5)
  const [tuneDialogOpen, setTuneDialogOpen] = useState(false)
  const [showAssemblyMetrics, setShowAssemblyMetrics] = useState(true)
  const [showAlignmentMetrics, setShowAlignmentMetrics] = useState(true)
  const [showAssemblyMBsToSpecies, setShowAssemblyMBsToSpecies] = useState(true)
  const [showContigsToSpecies, setShowContigsToSpecies] = useState(true)
  const [showCoverageBreadth, setShowCoverageBreadth] = useState(true)
  const [showCoverageDepth, setShowCoverageDepth] = useState(true)
  const [showCoverage5xDepth, setShowCoverage5xDepth] = useState(true)
  const [showRnDModels, setShowRnDModels] = useState(false)
  const [showImputedCalls, setShowImputedCalls] = useState(false)
  const [showResistanceGenes, setShowResistanceGenes] = useState(false)
  const [loadingResistanceGenes, setLoadingResistanceGenes] = useState(0)
  const [loadingImputations, setLoadingImputations] = useState(0)

  const handleIsDiagnosticViewChange = (event, newValue) => {
    setIsDiagnosticView(newValue === 'diagnostic');
  };

  useEffect(() => {
      if (!run) {
          return
      }

      const isIllumina = run.attributes.sequencing_platform === 'illumina' || run.attributes.platform === 'illumina'
      setShowKIDCalls(!isIllumina)
  }, [run])

  useEffect(() => {
      if (!activeProject) {
          return
      }

      // const canShowDiagnosticView = activeProject.attributes.organization_id === 1
      // setCanShowDiagnosticView(canShowDiagnosticView)
      setCanShowDiagnosticView(false)
  }, [activeProject])

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/sequencing_runs/${params.runId}?extra_fields=platform`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        setRun(response.data);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, params]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${params.sampleId}`,
            {
              method: 'GET',
              headers,
            },
          )
        })
      .then((res) => res.json())
      .then((response) => {
        setSample(response.data);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, params]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!sample) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/lib_seqs/${sample.id}/analyses?primary_only=True`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data.length) {
          return;
        }

        setActiveAnalysis(response.data[0]);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, sample]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!activeAnalysis) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${activeAnalysis.id}?extra_fields=pdf_report_signed_url,pdf_diagnostic_report_signed_url`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data) {
          return;
        }

        setActiveAnalysisReports(response.data);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeAnalysis]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!activeAnalysis) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/analyses/${activeAnalysis.id}/identified_species`,
            {
              method: 'GET',
              headers,
            },
          )
          })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data) {
          return;
        }

        const identifiedSpecies = response.data.sort(
            (a, b) => {
                const isIllumina = run.attributes.sequencing_platform === 'illumina' || run.attributes.platform === 'illumina'
                if (isIllumina) {
                    return b.attributes.reads_frac_agreeing_megabases - a.attributes.reads_frac_agreeing_megabases
                }

                return a.attributes.species_call_rank_by_kid_value - b.attributes.species_call_rank_by_kid_value
            },
          )
            .filter(item => {
                const isIllumina = run.attributes.sequencing_platform === 'illumina' || run.attributes.platform === 'illumina'
                if (isIllumina) {
                    return item.attributes.reads_frac_agreeing_megabases >= 0.8 || item.attributes.contigs_to_species > 0
                }

                return item.attributes.species_call_rank_by_kid_value !== 0
            })
        setAllIdentifiedSpecies(response.data)
        setIdentifiedSpecies(identifiedSpecies)
        setVisibleSpeciesCt(Math.min(identifiedSpecies.length, 5))
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, activeAnalysis, run]);

  const titleText = isDiagnosticView ? 'Keynome Cloud Diagnostic Results' : 'Keynome Cloud RUO Results';

  let downloadReportContent = null;
  if (activeAnalysisReports && canShowDiagnosticView) {
    const downloadReportHref = isDiagnosticView
      ? activeAnalysisReports.attributes.pdf_diagnostic_report_signed_url
      : activeAnalysisReports.attributes.pdf_report_signed_url;

    downloadReportContent = (
      <a target="_blank" href={downloadReportHref} rel="noreferrer">
        <Button size="small" variant="contained" sx={{ color: 'white' }}><b>{isDiagnosticView ? 'PDF REPORT' : 'GENERATE REPORTS'}</b></Button>
      </a>
    );
  }

  const runMetadataContent = isDiagnosticView
    ? <TestMetadataSummary run={run} sample={sample} isDiagnosticView={isDiagnosticView} />
    : <DetailedRunMetadataSummary run={run} />;

  const sampleMetadataContent = isDiagnosticView
    ? <DiagnosticSampleMetadataSummary sample={sample} />
    : <DetailedSampleMetadataSummary sample={sample} analysis={activeAnalysis} activeProject={activeProject} />;

  const isIllumina = run?.attributes?.sequencing_platform === 'illumina' || run?.attributes?.platform === 'illumina'

  const microbialMegabases = allIdentifiedSpecies
    ?.filter(species => !isIllumina || (species.attributes.contigs_to_species ?? 0) > 0)
    .reduce((sum, species) => sum + (species.attributes.reads_megabases_to_species ?? 0), 0);

  const microbialReads = allIdentifiedSpecies
    ?.filter(species => !isIllumina || (species.attributes.contigs_to_species ?? 0) > 0)
    .reduce((sum, species) => sum + (species.attributes.reads_to_species ?? 0), 0);

  let identifiedSpeciesContent = <LinearProgress />;
  if (identifiedSpecies) {
    identifiedSpeciesContent = identifiedSpecies.filter(
      (item) => {
        if (!isDiagnosticView) {
          return true;
        }

        return item.attributes.species_call_positive;
      },
    ).slice(0, isDiagnosticView ? 5 : visibleSpeciesCt).map(
      (item) => (
        <IdentifiedSpeciesRow
          isIllumina={isIllumina}
          identifiedSpecies={item}
          isDiagnosticView={isDiagnosticView}
          showAST={showAST}
          showKIDCalls={showKIDCalls}
          showRnDModels={showRnDModels}
          showImputedCalls={showImputedCalls}
          showAssemblyMetrics={showAssemblyMetrics}
          showAssemblyMBsToSpecies={showAssemblyMBsToSpecies}
          showContigsToSpecies={showContigsToSpecies}
          showAlignmentMetrics={showAlignmentMetrics}
          showCoverageBreadth={showCoverageBreadth}
          showCoverageDepth={showCoverageDepth}
          showResistanceGenes={showResistanceGenes}
          q20Rate={activeAnalysis?.attributes.q20_rate}
          assemblyN50={activeAnalysis?.attributes.assembly_n50}
          assemblyCoverageLT2xDepth={activeAnalysis?.attributes.assembly_ratio_lt_2x_coverage}
          totalMicrobialMegabases={microbialMegabases}
          setLoadingResistanceGenes={setLoadingResistanceGenes}
          setLoadingImputations={setLoadingImputations}
        />
      ),
    );
  }

  let uheDetailContent = null;
  let lpSeqDetailContent = null;
  let panelDetailContent = null;
  if (isDiagnosticView) {
    uheDetailContent = (
      <Grid item xs={12}>
        <Paper elevation={2}>
          <UHETestSummary />
        </Paper>
      </Grid>
    );

    lpSeqDetailContent = (
      <Grid item xs={12}>
        <Paper elevation={2}>
          <LPSeqTestSummary />
        </Paper>
      </Grid>
    );

    panelDetailContent = (
      <Grid item xs={12}>
        <Paper elevation={2}>
          <TestPanel />
        </Paper>
      </Grid>

    );
  }

  return (
    <Box>
      <Grid container sx={{ borderBottom: '#bdbdbd 2px solid' }}>
        <Grid item xs={12}>
          <Box>
            <Grid container maxWidth="lg" sx={{ m: 'auto', p: '10px' }}>
              <Grid item xs={2} sx={{ borderRight: '#bdbdbd 2px solid' }}>
                <Box>
                  <BackButton backToLink="/" backButtonText="ALL SAMPLES" />
                </Box>
              </Grid>
              <Grid item xs={4} align="left" sx={{ pt: 0.5, pl: 2 }}>
                {titleText}
              </Grid>
              <Grid item xs={4} align="right" sx={{ pl: 2 }}>
                {canShowDiagnosticView ? 
                    (
                        <ToggleButtonGroup
                        size="small"
                            value={isDiagnosticView ? 'diagnostic' : 'ruo'}
                          exclusive    
                          onChange={handleIsDiagnosticViewChange}
                        >
                            <ToggleButton color="primary" value="ruo"><small><b>RESEARCH VIEW</b></small></ToggleButton>
                            <ToggleButton color="primary" value="diagnostic"><small><b>DIAGNOSTIC VIEW</b></small></ToggleButton>
                        </ToggleButtonGroup>
                    ) : null
                }
              </Grid>
              <Grid item xs={2} align="right">
                {downloadReportContent}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container maxWidth="lg" sx={{ m: 'auto', mb: 2, paddingRight: '30px' }} spacing={2} align="auto">
      {
          isDiagnosticView ? null : (
              <Grid item xs={12}>
                  <Alert severity="warning"><b>For Research Use Only.</b> Not for use in diagnostic procedures.</Alert>
              </Grid>
          )
      }
        <Grid item xs={12} sm={6}>
          <Paper elevation={2}>
            {runMetadataContent}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={2}>
            {sampleMetadataContent}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={2}>
                <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
                <Grid container>
                    <Grid item xs={2.5}>
                  <p>
                    <b>
                      { isDiagnosticView ? 'DETECTED' : 'IDENTIFIED'}
                      {' '}
                      ORGANISMS
                    </b>
                  </p>
                    </Grid>
                    <Grid item xs={6}>
                        <p><small>Displaying data for <b>{visibleSpeciesCt}</b> of <b>{identifiedSpecies?.length}</b> total {(showKIDCalls && identifiedSpecies) ? `(${identifiedSpecies.filter(item => item.attributes.species_call_positive).length} positive; ${identifiedSpecies.filter(item => !item.attributes.species_call_positive).length} subthreshold)` : ''} organisms</small></p>
                    </Grid>
                   <Grid xs={3.5} sx={{textAlign: 'right', paddingTop: "10px"}}> 
                        {isDiagnosticView ? null : (
                      <Button size="small" color="primary" variant="contained" aria-label="open-tune-dialog" onClick={() => setTuneDialogOpen(true)} startIcon={<TuneIcon />}>
                            CHANGE RESULTS DISPLAY
                      </Button>
                        )}
                    </Grid>
                </Grid>
                </Box>
                <Box>{identifiedSpeciesContent}</Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {uheDetailContent}
        {lpSeqDetailContent}
        {panelDetailContent}
        <IdentifiedSpeciesFiltersDialog 
            isIllumina={run ? (run.attributes.sequencing_platform === 'illumina' || run.attributes.platform === 'illumina') : null}
            open={tuneDialogOpen} 
            setOpen={setTuneDialogOpen} 
            showAST={showAST}
            setShowAST={setShowAST}
            showKIDCalls={showKIDCalls}
            setShowKIDCalls={setShowKIDCalls}
            setVisibleSpeciesCt={setVisibleSpeciesCt}
            identifiedSpecies={identifiedSpecies}
            {...
                    {showAssemblyMetrics,
                showAlignmentMetrics,
                setShowAssemblyMetrics,
                setShowAlignmentMetrics,
                showAssemblyMBsToSpecies,
                setShowAssemblyMBsToSpecies,
                showContigsToSpecies,
                setShowContigsToSpecies,
                showCoverageBreadth,
                setShowCoverageBreadth,
                showCoverageDepth,
                setShowCoverageDepth,
                showCoverage5xDepth,
                setShowCoverage5xDepth,
                showRnDModels,
                setShowRnDModels,
                showImputedCalls,
                setShowImputedCalls,
                visibleSpeciesCt,
                showResistanceGenes,
                setShowResistanceGenes,
                loadingResistanceGenes,
                loadingImputations
                }
            
            }
        />
        {isDiagnosticView ? null : (
            <>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <DetailedSequencingYieldSummary analysis={activeAnalysis} microbialMegabases={microbialMegabases} microbialReads={microbialReads} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={2}>
            <DetailedSequencingQualitySummary analysis={activeAnalysis} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={2}>
            <DetailedAssemblyQualitySummary analysis={activeAnalysis} 
            isIllumina={run?.attributes.sequencing_platform === 'illumina' || run?.attributes.platform === 'illumina'}
            />
          </Paper>
        </Grid>
            </>
        )}
      </Grid>
    </Box>
  );
}

export default Sample;
