import React from 'react';
import { Box, Grid } from '@mui/material';

function DetailedAssemblyQualitySummary(props) {
  const {
    analysis,
    isIllumina
  } = props;

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>{isIllumina ? 'POLISHED ' : ''}DE NOVO ASSEMBLY QUALITY</b> <small style={{color: "gray"}}>(prior to taxonomic binning)</small></p>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12}>
      <Box sx={{ padding: '15px 40px' }}>
            <Grid container>
        <Grid item xs={12} sm={6}>
        <p>
          <b>Total Length:</b>
          {' '}
          {(analysis && (analysis.attributes.assembly_length || analysis.attributes.total_length)) ? ((analysis.attributes.assembly_length || analysis.attributes.total_length) / 1000000).toLocaleString() : '-'} MBs
        </p>
        <p>
          <b>Contig N50:</b>
          {' '}
          {(analysis && analysis.attributes.assembly_n50) ? analysis.attributes.assembly_n50.toLocaleString() : '-'} bases
        </p>
        <p>
          <b>Contig N90:</b>
          {' '}
          {(analysis && analysis.attributes.polished_assembly_n90) ? analysis.attributes.polished_assembly_n90.toLocaleString() : '-'} bases
        </p>
        </Grid>
        <Grid item xs={12} sm={6}>
        <p>
          <b>Number of Contigs:</b>
          {' '}
          {(analysis && (analysis.attributes.assembly_contigs_count || analysis.attributes.n_contigs)) ? (analysis.attributes.assembly_contigs_count || analysis.attributes.n_contigs).toLocaleString() : '-'}
        </p>
        <p>
          <b>Contig L50:</b>
          {' '}
          {(analysis && analysis.attributes.assembly_l50) ? analysis.attributes.assembly_l50.toLocaleString() : '-'}
        </p>
      </Grid>
        <Grid item xs={12}>
            <p>
              <b>Average Depth:</b>
              {' '}
              {(analysis && analysis.attributes.assembly_mean_coverage) ? (analysis.attributes.assembly_mean_coverage).toFixed(2) : '-'}
            </p>
            <p>
              <b>Assembly coverage {(analysis && analysis.attributes.polished_assembly_fraction_5x_coverage) ? '>=' : '>'}5x depth:</b>
              {' '}
              {(analysis && (analysis.attributes.polished_assembly_fraction_5x_coverage || analysis.attributes.assembly_ratio_lt_6x_coverage)) ? (analysis.attributes.polished_assembly_fraction_5x_coverage ? (analysis.attributes.polished_assembly_fraction_5x_coverage * 100).toFixed(2) : ((1 - analysis.attributes.assembly_ratio_lt_6x_coverage) * 100).toFixed(2)) : '-'}%
                <br />
              <small style={{color: 'gray'}}>alignment of reads back to assembly with high mapping quality requirement</small>
            </p>
        </Grid>
      </Grid>
      </Box>
      </Grid>
      </Grid>
    </>
  );
}

export default DetailedAssemblyQualitySummary;
