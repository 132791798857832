import { useParams, useLocation } from 'react-router-dom';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import logo from './dzd-logo.svg';
import ProjectSelect from './Projects/ProjectSelect';
import UserDropdown from './User/UserDropdown';
import SampleSearchAutocomplete from './Samples/SampleSearchAutocomplete'

const styles = {
  flag: {
    marginLeft: 7,
    marginBottom: -2,
    height: '15px',
    display: 'inline-block'
  },
};

const deploymentIndicators = {
    eu: {
        iconSrc: '/eu_flag.png',
        iconAlt: 'EU Flag'
    },
    us: {
        iconSrc: '/us_flag.png',
        iconAlt: 'US Flag'
    },
    ceph: {
        iconSrc: '/ceph_logo.png',
        iconAlt: 'Cepheid Logo'
    }
}

const getRegionDetails = () => {
    let deploymentIndicatorLabel;
    if (process.env.REACT_APP_KEYNOME_API_URL_BASE.startsWith("https://eu.keynome.api")) {
        deploymentIndicatorLabel = 'eu'
    } else if (process.env.REACT_APP_KEYNOME_API_URL_BASE.startsWith("https://cepheid.keynome.api")) {
        deploymentIndicatorLabel = 'ceph'
    } else {
        deploymentIndicatorLabel = 'us'
    }

    return {
        ...{label: deploymentIndicatorLabel.toUpperCase()},
        ...deploymentIndicators[deploymentIndicatorLabel]
    };
};

function ResponsiveAppBar(props) {
    const params = useParams();
    const {
      pathname
    } = useLocation();

    const {
        projects, 
        activeProject,
        reloadProjects,
        setActiveProject,
        onActiveProjectChange, 
        userData, 
        organization, 
        setSamples, 
        datasetsGridRef,
        setShowDocs
    } = props;

    let projectSelectContent = null;
    let sampleSearchContent = null;
    if (!params.sampleId && pathname !== '/profile') {
        projectSelectContent = (
            <ProjectSelect
            projects={projects}
            reloadProjects={reloadProjects}
            activeProject={activeProject}
            onActiveProjectChange={onActiveProjectChange}
            setActiveProject={setActiveProject}
            />
        );

        sampleSearchContent = (
            <SampleSearchAutocomplete
            activeProject={activeProject}
            setSamples={setSamples} 
            datasetsGridRef={datasetsGridRef}
            />
        );
    }

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '#bdbdbd 2px solid' }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ padding: '0 40px 0 0' }}>
                <img src={logo} alt="dzd-logo" />
              </Box>

              <Box sx={{ padding: '15px 20px 15px 0', minWidth: '150px' }}>
                {projectSelectContent}
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, padding: '15px 20px 15px 0' }}>
                {sampleSearchContent}
              </Box>

              <Box sx={{ flexGrow: 0, paddingRight: 5 }}>
                <h3 style={{color: 'gray', fontWeight: 'normal'}}><span style={{fontWeight: "bold"}}>{userData?.data.attributes.name}</span> | {organization?.attributes.name}{' '}
                <img
                  src={getRegionDetails().iconSrc}
                  alt={getRegionDetails().iconAlt}
                  style={styles.flag}
                />
              </h3>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <UserDropdown setShowDocs={setShowDocs} />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
